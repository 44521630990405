/* (Radhakrishnan, Subhash) - 1002169517
(Rachuri, Naresh) - 1002199076
(Potluri, Sanjana) - 1002147971
(Puvvada, Srikari Deepthi) - 1002157559
(Paturu, Sasikala) - 1002083123 */
.events-page {
    padding: 2rem;
    text-align: center;
  }
  
  .event-list {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  
  .event-card {
    background-color: #F4A261;
    padding: 1rem;
    border-radius: 8px;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .events-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 2rem;
    min-height: 75vh; /* Full viewport height */
    background: linear-gradient(135deg, #b37ded 0%, #2575fc 100%); /* Soft gradient */
    color: white; /* White text on gradient */
    text-align: center;
}
  