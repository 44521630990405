/* (Radhakrishnan, Subhash) - 1002169517
(Rachuri, Naresh) - 1002199076
(Potluri, Sanjana) - 1002147971
(Puvvada, Srikari Deepthi) - 1002157559
(Paturu, Sasikala) - 1002083123 */
.about-page {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    text-align: left;
    padding-left: 40px; /* Adjusts the indent on the left */
    padding-right: 40px; /* Adjusts the indent on the right */
    background: linear-gradient(135deg, #e0eafc, #cfdef3); /* Gradient background */
    border-radius: 10px; /* Adds rounded corners to the content area */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
  }
  
  .about-page h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .about-page h2 {
    font-size: 1.8rem;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  
  .about-page p {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 20px;
    text-align: justify; /* Justifies text for better readability */
  }
  
  .about-page ul {
    list-style-type: disc;
    padding-left: 40px;
  }
  
  .about-page ul li {
    margin-bottom: 10px;
  }
  
  /* Apply background gradient to the entire page */
    body {
        background: linear-gradient(135deg, #89f7fe, #66a6ff); /* Light blue gradient */
        min-height: 100vh;
    }