/* (Radhakrishnan, Subhash) - 1002169517
(Rachuri, Naresh) - 1002199076
(Potluri, Sanjana) - 1002147971
(Puvvada, Srikari Deepthi) - 1002157559
(Paturu, Sasikala) - 1002083123 */
.mentorship-page-mentor {
    padding: 2rem;
    max-width: 1200px;
    margin: auto;
  }
  
  .mentor-hero {
    text-align: center;
    background-color: #e0f7fa;
    padding: 3rem;
    border-radius: 8px;
    margin-bottom: 2rem;
  }
  
  .mentor-hero h1 {
    font-size: 2.5rem;
    color: #00796b;
  }
  
  .mentor-hero p {
    font-size: 1.2rem;
    color: #004d40;
  }
  
  .mentor-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 2rem;
    min-height: 5vh; /* Full viewport height */
    background: linear-gradient(135deg, #b37ded 0%, #eae088 100%); /* Soft gradient */
    color: white; /* White text on gradient */
    text-align: center;
}