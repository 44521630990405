/* (Radhakrishnan, Subhash) - 1002169517
(Rachuri, Naresh) - 1002199076
(Potluri, Sanjana) - 1002147971
(Puvvada, Srikari Deepthi) - 1002157559
(Paturu, Sasikala) - 1002083123 */
.call-for-papers {
    padding: 20px;
    background-color: #fac5c5;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-height: calc(100vh - 120px); /* Adjust height to fit the screen minus footer */
    display: flex;
    flex-direction: column; /* Align content vertically */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    text-align: center; /* Center text */
}

.call-for-papers h1 {
    color: #2A9D8F;
}

.call-for-papers ul {
    list-style-type: square;
    margin-left: 20px;
}

.call-for-papers p {
    margin: 10px 0;
}
