/* (Radhakrishnan, Subhash) - 1002169517
(Rachuri, Naresh) - 1002199076
(Potluri, Sanjana) - 1002147971
(Puvvada, Srikari Deepthi) - 1002157559
(Paturu, Sasikala) - 1002083123 */
.communication-panel {
    margin-top: 3rem;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 8px;
    text-align: center;
  }
  
  .communication-panel select {
    padding: 0.5rem;
    width: 100%;
    margin-bottom: 1rem;
  }
  
  .communication-panel textarea {
    width: 100%;
    padding: 1rem;
    border-radius: 8px;
    border: 1px solid #ddd;
    min-height: 120px;
  }
  
  .send-message-btn {
    margin-top: 1rem;
    padding: 0.8rem 1.5rem;
    background-color: #00796b;
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
  }
  
  .communication-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 2rem;
    min-height: 35vh; /* Full viewport height */
    background: linear-gradient(135deg, #b37ded 0%, #eae088 100%); /* Soft gradient */
    color: white; /* White text on gradient */
    text-align: center;
}