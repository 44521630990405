/* (Radhakrishnan, Subhash) - 1002169517
(Rachuri, Naresh) - 1002199076
(Potluri, Sanjana) - 1002147971
(Puvvada, Srikari Deepthi) - 1002157559
(Paturu, Sasikala) - 1002083123 */
.account-settings {
  padding: 2rem;
  max-width: 800px;
  margin: auto;
}

.settings-nav {
  display: flex;
  justify-content: space-around;
  margin-bottom: 2rem;
}

.settings-nav button {
  padding: 0.8rem 1.5rem;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  border-radius: 25px;
}

.settings-nav button.active {
  background-color: #00796b;
  color: white;
}

.settings-content {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

