/* (Radhakrishnan, Subhash) - 1002169517
(Rachuri, Naresh) - 1002199076
(Potluri, Sanjana) - 1002147971
(Puvvada, Srikari Deepthi) - 1002157559
(Paturu, Sasikala) - 1002083123 */

.contact-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 2rem;
    min-height: 75vh; /* Full viewport height */
    background: linear-gradient(135deg, #b37ded 0%, #2575fc 100%); /* Soft gradient */
    color: white; /* White text on gradient */
    text-align: center;
}

.contact-form {
    background-color: rgba(234, 121, 254, 0.1); /* Light transparent background */
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px; /* Form size limit */
}

.contact-form label {
    margin: 1rem 0 0.5rem;
    font-weight: bold;
    color: #f0e6ff; /* Lighter text color for good readability */
    text-align: left;
    width: 100%;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    max-width: 100%;
    padding: 0.8rem;
    margin-top: 0.5rem;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5; /* Light background for input */
    font-size: 1rem;
}

.contact-form textarea {
    height: 100px; /* Set height for textarea */
}

.contact-form button {
    margin-top: 1.5rem;
    padding: 0.75rem 1.5rem;
    background-color: #FF6F61; /* Bright button color */
    border: none;
    color: white;
    border-radius: 25px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.contact-form button:hover {
    background-color: #E65B50; /* Slightly darker on hover */
}

/* Additional Responsive Design */
@media (max-width: 600px) {
    .contact-form {
        padding: 1.5rem;
        width: 90%;
    }

    .contact-form input,
    .contact-form textarea {
        font-size: 0.9rem;
    }
}
