/* (Radhakrishnan, Subhash) - 1002169517
(Rachuri, Naresh) - 1002199076
(Potluri, Sanjana) - 1002147971
(Puvvada, Srikari Deepthi) - 1002157559
(Paturu, Sasikala) - 1002083123 */
body, html {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #F4F4F9;
    color: #333333;
  }
  
  h1, h2, h3, h4, h5, h6 {
    color: #264653;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  