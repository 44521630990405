/* (Radhakrishnan, Subhash) - 1002169517
(Rachuri, Naresh) - 1002199076
(Potluri, Sanjana) - 1002147971
(Puvvada, Srikari Deepthi) - 1002157559
(Paturu, Sasikala) - 1002083123 */
.dashboard {
    padding: 2rem;
    text-align: center;
    min-height: 79vh; /* Full viewport height */
    background: linear-gradient(135deg, #b37ded 0%, #2575fc 100%); /* Soft gradient */
    color: white; /* White text on gradient */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
}

.admin-dashboard {
    background: linear-gradient(135deg, #ff7e5f 0%, #feb47b 100%); /* Admin gradient */
}

.mentor-dashboard {
    background: linear-gradient(135deg, #43e97b 0%, #38f9d7 100%); /* Mentor gradient */
}

.student-dashboard {
    background: linear-gradient(135deg, #ff6a6a 0%, #fbc2eb 100%); /* Student gradient */
}

.dashboard-buttons {
    display: flex;
    justify-content: center; /* Center buttons horizontally */
    gap: 20px; /* Space between buttons */
    margin-top: 30px; /* Space above buttons */
}

.dashboard-button {
    text-decoration: none;    
    background-color: #2A9D8F; /* Button color */
    color: white;
    padding: 12px 30px;
    border-radius: 25px; /* Rounded button */
    font-size: 1.2rem;
    font-weight: bold;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Button shadow */
}

.dashboard-button:hover {
    background-color: #21867A; /* Darker shade on hover */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Increased shadow on hover */
}


