/* (Radhakrishnan, Subhash) - 1002169517
(Rachuri, Naresh) - 1002199076
(Potluri, Sanjana) - 1002147971
(Puvvada, Srikari Deepthi) - 1002157559
(Paturu, Sasikala) - 1002083123 */
.session-management {
    margin-top: 3rem;
  }
  
  .sessions-list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .session-card {
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .session-card h3 {
    font-size: 1.2rem;
    color: #333;
  }
  
  .session-card p {
    font-size: 0.9rem;
    color: #777;
  }
  
  .session-card.upcoming {
    border-left: 4px solid #4caf50;
  }
  
  .session-card.completed {
    border-left: 4px solid #ff9800;
  }
  
  .reschedule-btn {
    padding: 0.5rem 1rem;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 0.9rem;
  }
  
  