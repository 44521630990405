/* (Radhakrishnan, Subhash) - 1002169517
(Rachuri, Naresh) - 1002199076
(Potluri, Sanjana) - 1002147971
(Puvvada, Srikari Deepthi) - 1002157559
(Paturu, Sasikala) - 1002083123 */

.jobs-page {
  padding: 20px;
}

.job-listings {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between job cards */
}

.job-card {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.apply-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.apply-button:hover {
  background-color: #0056b3;
}

.jobs-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 2rem;
    min-height: 75vh; /* Full viewport height */
    background: linear-gradient(135deg, #b37ded 0%, #eae088 100%); /* Soft gradient */
    color: white; /* White text on gradient */
    text-align: center;
}