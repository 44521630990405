/* (Radhakrishnan, Subhash) - 1002169517
(Rachuri, Naresh) - 1002199076
(Potluri, Sanjana) - 1002147971
(Puvvada, Srikari Deepthi) - 1002157559
(Paturu, Sasikala) - 1002083123 */
.student-mentorship-page {
    padding: 2rem;
    max-width: 1200px;
    margin: auto;
  }
  
  .mentorship-hero {
    text-align: center;
    background-color: #f0f4ff;
    padding: 3rem;
    border-radius: 8px;
    margin-bottom: 2rem;
  }
  
  .mentorship-hero h1 {
    font-size: 2.5rem;
    color: #333;
  }
  
  .mentorship-hero p {
    font-size: 1.2rem;
    color: #666;
  }
  
  .find-mentor-btn {
    padding: 0.8rem 1.5rem;
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 1rem;
  }
  
  .mentorship-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 2rem;
    min-height: 25vh; /* Full viewport height */
    background: linear-gradient(135deg, #b37ded 0%, #eae088 100%); /* Soft gradient */
    color: white; /* White text on gradient */
    text-align: center;
}