/* (Radhakrishnan, Subhash) - 1002169517
(Rachuri, Naresh) - 1002199076
(Potluri, Sanjana) - 1002147971
(Puvvada, Srikari Deepthi) - 1002157559
(Paturu, Sasikala) - 1002083123 */

.register-button {
    background-color: #2A9D8F;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.register-button:hover {
    background-color: #21867A;
}
    /* src/css/Registration.css */
.registration {
    padding: 20px;
    background-color: #e7f2fe;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-height: calc(100vh - 120px); /* Adjust height to fit the screen minus footer */
    display: flex;
    flex-direction: column; /* Align content vertically */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    text-align: center; /* Center text */
}

.registration h1 {
    color: #2A9D8F;
}

.registration form {
    display: flex;
    flex-direction: column; /* Align form elements vertically */
    align-items: center; /* Center form elements */
}

.form-group {
    margin: 10px 0; /* Space between form elements */
}

.form-group label {
    margin-right: 10px; /* Space between label and input */
}

.form-group input {
    padding: 8px; /* Padding inside the input */
    width: 300px; /* Fixed width for input fields */
    border: 1px solid #ccc; /* Light border */
    border-radius: 4px; /* Rounded corners */
}

.form-group button {
    padding: 10px 20px; /* Padding for button */
    background-color: #2A9D8F; /* Button color */
    color: white; /* Text color */
    border: none; /* Remove default border */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.form-group button:hover {
    background-color: #21867A; /* Darker button color on hover */
}

