/* (Radhakrishnan, Subhash) - 1002169517
(Rachuri, Naresh) - 1002199076
(Potluri, Sanjana) - 1002147971
(Puvvada, Srikari Deepthi) - 1002157559
(Paturu, Sasikala) - 1002083123 */
.signup-container {
    width: 300px;
    margin: 0px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #f9f9f9;
  }
  .signup-page {
    flex-direction: column;
    align-items: center;
    padding: 3rem 2rem;
    min-height: 75vh; /* Full viewport height */
    background: linear-gradient(135deg, #b37ded 0%, #2575fc 100%); /* Soft gradient */
    /* text-align: center; */
}
  .signup-form {
    display: flex;
    flex-direction: column;
  }
  
  .signup-form label {
    margin-bottom: 5px;
  }
  
  .signup-form input,
  .signup-form select {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .signup-button {
    padding: 10px 20px;
    background-color: #e76f51;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .signup-button:hover {
    background-color: #f4a261;
  }
  
  .loginLink {
    color: blueviolet;
  }

  .signup-message {
    background-color: #d4edda; /* Light green background */
    color: #155724; /* Dark green text color */
    border: 1px solid #c3e6cb; /* Border to match the background */
    padding: 10px;
    margin-top: 15px;
    border-radius: 5px;
    text-align: center;
  }
