/* (Radhakrishnan, Subhash) - 1002169517
(Rachuri, Naresh) - 1002199076
(Potluri, Sanjana) - 1002147971
(Puvvada, Srikari Deepthi) - 1002157559
(Paturu, Sasikala) - 1002083123 */
.mentorship-requests {
    margin-top: 3rem;
  }
  
  .requests-list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .request-card {
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .request-card h3 {
    font-size: 1.2rem;
    color: #333;
  }
  
  .request-card p {
    font-size: 0.9rem;
    color: #777;
  }
  
  .accept-request-btn {
    padding: 0.5rem 1rem;
    background-color: #00796b;
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 0.9rem;
  }

  .mentorship-requests {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 2rem;
    min-height: 35vh; /* Full viewport height */
    background: linear-gradient(135deg, #b37ded 0%, #eae088 100%); /* Soft gradient */
    color: white; /* White text on gradient */
    text-align: center;
}
  