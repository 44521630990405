/* (Radhakrishnan, Subhash) - 1002169517
(Rachuri, Naresh) - 1002199076
(Potluri, Sanjana) - 1002147971
(Puvvada, Srikari Deepthi) - 1002157559
(Paturu, Sasikala) - 1002083123 */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #264653;
  }
  
  .logo {
    color: #F4A261;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .nav-links {
    display: flex;
    gap: 1rem;
  }
  
  .nav-item {
    color: #F4A261;
    text-decoration: none;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .nav-item:hover {
    background-color: #E76F51;
  }
  
  .active-link {
    background-color: #E76F51; /* Highlight color for active link */
    color: white;
    font-weight: bold;
  }
  
  /* src/css/Header.css */
.logout-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 8px 16px;
  margin-left: 20px;
  cursor: pointer;
  border-radius: 4px;
}

.logout-button:hover {
  background-color: #d32f2f;
}
