/* (Radhakrishnan, Subhash) - 1002169517
(Rachuri, Naresh) - 1002199076
(Potluri, Sanjana) - 1002147971
(Puvvada, Srikari Deepthi) - 1002157559
(Paturu, Sasikala) - 1002083123 */

.networking-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 2rem;
    min-height: 75vh; /* Full viewport height */
    background: linear-gradient(135deg, #b37ded 0%, #2575fc 100%); /* Soft gradient */
    color: white; /* White text on gradient */
    text-align: center;
}

.networking-page h1 {
    font-size: 2.5rem; /* Larger title */
    margin-bottom: 2rem;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Subtle text shadow */
}

.networking-links {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap on smaller screens */
    justify-content: center; /* Centers links horizontally */
    margin: 2rem 0;
}

.networking-links a {
    margin: 1rem;
    padding: 1rem 2rem; /* Increase padding for better spacing */
    background-color: #2A9D8F; /* Greenish button color */
    color: #FFFFFF;
    text-decoration: none;
    border-radius: 30px; /* More rounded buttons */
    font-size: 1.2rem;
    transition: transform 0.3s ease, background-color 0.3s ease; /* Smooth hover effects */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds slight shadow */
}

.networking-links a:hover {
    background-color: #21867a; /* Darker shade on hover */
    transform: scale(1.05); /* Slightly enlarges on hover */
}

.networking-page p {
    font-size: 1.1rem;
    margin-top: 2rem;
    line-height: 1.6; /* Better line spacing for readability */
    max-width: 700px; /* Limit the width for better readability */
    color: #e0e0e0; /* Light text color */
}



/* Responsive Design */
@media (max-width: 768px) {
    .networking-page h1 {
        font-size: 2rem;
    }

    .networking-links a {
        font-size: 1rem;
        padding: 0.75rem 1.5rem;
    }
}
