/* (Radhakrishnan, Subhash) - 1002169517
(Rachuri, Naresh) - 1002199076
(Potluri, Sanjana) - 1002147971
(Puvvada, Srikari Deepthi) - 1002157559
(Paturu, Sasikala) - 1002083123 */
.interest-based-groups {
    padding: 20px;
    background: linear-gradient(135deg, #b37ded 0%, #2575fc 100%); /* Soft gradient */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-height: calc(100vh - 120px); /* Adjust height to fit the screen minus footer */
    display: flex;
    flex-direction: column; /* Align content vertically */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    text-align: center; /* Center text */
}

.interest-based-groups h1 {
    color: #eff4f3;
}

.interest-based-groups ul {
    list-style: none; /* Remove default list styling */
    padding: 0; /* Remove default padding */
    text-align: left; /* Align list items to the left */
}

.interest-based-groups ul li {
    margin: 10px 0; /* Space between group items */
}
