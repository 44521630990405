/* (Radhakrishnan, Subhash) - 1002169517
(Rachuri, Naresh) - 1002199076
(Potluri, Sanjana) - 1002147971
(Puvvada, Srikari Deepthi) - 1002157559
(Paturu, Sasikala) - 1002083123 */
.conference-schedule {
    padding: 20px;
    background-color: #fff7e6;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-height: calc(100vh - 120px); /* Adjust height to fit the screen minus footer */
}

.conference-schedule h1 {
    color: #2575fc;
}

.conference-schedule h2 {
    color: #ff6f61;
}

.conference-schedule ul {
    list-style-type: none;
    margin-left: 20px;
}
