/* (Radhakrishnan, Subhash) - 1002169517
(Rachuri, Naresh) - 1002199076
(Potluri, Sanjana) - 1002147971
(Puvvada, Srikari Deepthi) - 1002157559
(Paturu, Sasikala) - 1002083123 */
.request-form {
    background-color: #fafafa;
    padding: 2rem;
    border-radius: 8px;
    margin-top: 3rem;
  }
  
  .request-form h2 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .request-form form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .request-form label {
    font-weight: bold;
    color: #333;
  }
  
  .request-form select, 
  .request-form button {
    padding: 0.8rem;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  .request-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 2rem;
    min-height: 25vh; /* Full viewport height */
    background: linear-gradient(135deg, #b37ded 0%, #eae088 100%); /* Soft gradient */
    color: white; /* White text on gradient */
    text-align: center;
}