/* (Radhakrishnan, Subhash) - 1002169517
(Rachuri, Naresh) - 1002199076
(Potluri, Sanjana) - 1002147971
(Puvvada, Srikari Deepthi) - 1002157559
(Paturu, Sasikala) - 1002083123 */
  .footer {
    margin-top: auto;
    background-color: #2A9D8F;
    color: #FFFFFF;
    text-align: center;
    padding: 1rem;
    max-width: 100%;
  }

  