/* (Radhakrishnan, Subhash) - 1002169517
(Rachuri, Naresh) - 1002199076
(Potluri, Sanjana) - 1002147971
(Puvvada, Srikari Deepthi) - 1002157559
(Paturu, Sasikala) - 1002083123 */
.session-history {
    margin-top: 3rem;
  }
  
  .session-card {
    background-color: #f9f9f9;
    padding: 1.5rem;
    border-radius: 8px;
    margin-bottom: 1rem;
  }
  
  .session-card h3 {
    font-size: 1.2rem;
    color: #333;
  }
  
  .leave-feedback-btn {
    padding: 0.5rem 1rem;
    background-color: #ff6f61;
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 0.9rem;
  }
  
  .session-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 2rem;
    min-height: 25vh; /* Full viewport height */
    background: linear-gradient(135deg, #b37ded 0%, #eae088 100%); /* Soft gradient */
    color: white; /* White text on gradient */
    text-align: center;
}