/* (Radhakrishnan, Subhash) - 1002169517
(Rachuri, Naresh) - 1002199076
(Potluri, Sanjana) - 1002147971
(Puvvada, Srikari Deepthi) - 1002157559
(Paturu, Sasikala) - 1002083123 */
.update-profile {
    padding: 1rem;
  }
  
  .profile-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .profile-form label {
    font-size: 1rem;
    font-weight: bold;
  }
  
  .profile-form input {
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
  }
  
  .save-profile-btn {
    padding: 0.8rem 1.5rem;
    background-color: #00796b;
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
  }
  