/* (Radhakrishnan, Subhash) - 1002169517
(Rachuri, Naresh) - 1002199076
(Potluri, Sanjana) - 1002147971
(Puvvada, Srikari Deepthi) - 1002157559
(Paturu, Sasikala) - 1002083123 */
.notification-preferences {
    padding: 1rem;
  }
  
  .preferences-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .preference-option {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .preference-option label {
    font-size: 1rem;
  }
  
  .save-preferences-btn {
    padding: 0.8rem 1.5rem;
    background-color: #00796b;
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
  }
  