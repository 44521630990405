/* (Radhakrishnan, Subhash) - 1002169517
(Rachuri, Naresh) - 1002199076
(Potluri, Sanjana) - 1002147971
(Puvvada, Srikari Deepthi) - 1002157559
(Paturu, Sasikala) - 1002083123 */
body, html {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif; /* Use a clean and modern font */
    background-color: #f4f4f9; /* Light background for contrast */
    color: #333; /* Primary text color */
    box-sizing: border-box;
}

.home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 2rem;
    min-height: 75vh; /* Full viewport height */
    background: linear-gradient(135deg, #b37ded 0%, #2575fc 100%); /* Soft gradient */
    color: white; /* White text on gradient */
    text-align: center;
}

.home-page h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    font-weight: bold;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2); /* Text shadow for emphasis */
}

.home-page p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    max-width: 800px;
    line-height: 1.5;
}

/* Conference Overview */
.conference-overview {
    background-color: rgba(255, 255, 255, 0.9); /* Light background for contrast */
    color: #333; /* Dark text */
    padding: 2rem;
    border-radius: 10px; /* Rounded corners */
    margin: 2rem 0;
    width: 100%; /* Full width */
    max-width: 800px; /* Max width for content */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow */
}

.conference-overview h2 {
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: bold;
}

/* Quick Links Section */
.quick-links {
    margin: 3rem 0; /* Increased space */
}

.quick-links h2 {
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: bold;
}

.links-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Allow links to wrap on smaller screens */
}

.quick-link {
    margin: 1rem;
    padding: 0.75rem 1.5rem;
    background-color: #2A9D8F;
    color: #FFFFFF;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1.2rem;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for 3D effect */
    transition: all 0.3s ease;
}

.quick-link:hover {
    background-color: #21867A;
    transform: translateY(-3px); /* Lift up effect */
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

/* Latest News Section */
.latest-news {
    background-color: rgba(255, 255, 255, 0.9); /* Light background for contrast */
    color: #333; /* Dark text */
    padding: 2rem;
    border-radius: 10px; /* Rounded corners */
    margin: 2rem 0;
    width: 100%; /* Full width */
    max-width: 800px; /* Max width for content */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow */
}

.latest-news h2 {
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: bold;
}

/* Links below the content */
.home-links {
    margin-top: 3rem; /* Increased space above links */
}

.home-link {
    text-decoration: none;
    background-color: #FF6F61; /* Vibrant color for action buttons */
    color: white;
    padding: 12px 30px;
    border-radius: 25px; /* Rounded pill-shaped buttons */
    margin: 0 15px;
    margin-top: 20px;
    font-size: 1.2rem;
    font-weight: bold;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Button shadow */
}

.home-link:hover {
    background-color: #E65B50; /* Darker shade on hover */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Increased shadow on hover */
}


/* Media Queries for responsiveness */
@media (max-width: 768px) {
    .home-page h1 {
        font-size: 2.5rem;
    }

    .home-page p {
        font-size: 1.2rem;
    }

    .quick-links a {
        padding: 0.5rem 1rem;
        font-size: 1rem;
    }

    .home-link {
        font-size: 1rem;
        padding: 10px 20px;
    }
}
